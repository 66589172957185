<template>
  <div>
    <b-modal
      ref="mdlDetalheEvento"
      v-if="eventoObj"
      hide-footer
      size="lg"
      ignore-enforce-focus-selector=".swal2-container"
      @hide="hideModal"
    >
      <template #modal-header="{ close }">
        <div class="d-flex justify-content-between w-100" style="height: 26px">
          <div>
            <!-- <div class="back-arrow-squared" @click="close()">
              <i
                class="flaticon-up-arrow"
                style="color: #808080 !important"
              ></i>
            </div> -->
          </div>
          <div class="d-flex align-items-center">
            <b-button
              v-if="eventoObj.agenda_calendario_status_id == 'NO'"
              class="text-uppercase"
              @click="editar(eventoCopia)"
              >Editar</b-button
            >
            <b-button-group class="ml-4">
              <b-button
                v-if="eventoObj.agenda_calendario_status_id == 'NO'"
                class="text-uppercase"
                variant="danger"
                @click="recusarEvento(eventoObj.agenda_calendario_id)"
                >Recusar</b-button
              >
              <b-button
                v-if="
                  eventoObj.agenda_calendario_status_id == 'NO' ||
                  (eventoObj.agenda_calendario_status_id == 'AP' &&
                    eventoObj.agc_sincronizado != 'S')
                "
                class="text-uppercase"
                variant="success"
                @click="aprovarEvento(eventoObj.agenda_calendario_id)"
                >Aprovar</b-button
              >
            </b-button-group>

            <b-button
              v-if="eventoObj.agenda_calendario_status_id == 'AP'"
              @click="cancelar(eventoObj.agenda_calendario_id)"
              variant="danger"
              class="ml-2 text-uppercase"
              >Cancelar</b-button
            >

            <div class="ml-5 back-arrow-squared" @click="close()">
              <button
                type="button"
                aria-label="Close"
                class=""
                @click="close()"
              >
                ×
              </button>
            </div>
          </div>
        </div>
      </template>
      <div
        class="align-items-center mb-10 mt-5 detalhes-collapse overflow-hidden"
      >
        <div
          class="row pb-5 align-items-center overflow-hidden"
          style="padding: 16px"
        >
          <div class="col-12 col-sm-2 col-xl-1 p-0">
            <section style="display: flex; align-items: center">
              <div
                class="ml-2"
                :style="`width: 10px; height: 10px; background-color: ${eventoObj.mar_cor}; border-radius: 50%`"
              />
              <div
                :class="`icon icon-size-g ${pegarIcone(
                  eventoObj.agenda_calendario_status_id,
                  eventoObj.agc_sincronizado
                )} ml-3`"
              />
            </section>
          </div>
          <div class="col-12 col-sm-6 col-xl-9 align-items-center">
            <span class="corpo-secao-descricao">
              {{
                formatarDataString(
                  `${eventoObj.agc_data_inicio} ${eventoObj.agc_hora_inicio}`,
                  "LLLL"
                )
              }}
            </span>
            <span class="corpo-secao-informacao">
              <strong>
                {{ eventoObj.agenda_calendario_id }} -
                {{ eventoObj.agc_nome }}
              </strong>
            </span>
          </div>

          <div class="col-12 col-sm-4 col-xl-2 d-flex flex-row-reverse">
            <!-- style="display: flex; justify-content: end" -->
            <b-button
              v-b-toggle="'cardCollapse'"
              style="padding: 0; border: none"
            >
              <a
                class="btn btn-icon btn-sm btn-light-primary card-button-collapse"
                style="width: 100px"
                @click="isDetalhesCollapsed = !isDetalhesCollapsed"
                >DETALHES
                <i
                  v-if="isDetalhesCollapsed"
                  class="ki ki-arrow-down icon-nm ml-2"
                  style="color: inherit"
                ></i>
                <i
                  v-if="!isDetalhesCollapsed"
                  class="ki ki-arrow-up icon-nm ml-2"
                  style="color: inherit"
                ></i>
              </a>
            </b-button>
          </div>
        </div>
        <b-collapse
          id="cardCollapse"
          visible
          style="border-top: 1px solid #eee; padding: 16px"
        >
          <div class="row">
            <div class="col-12 col-sm-6 col-xl-4 modal-detalhes-corpo-secao">
              <span class="corpo-secao-descricao">Status</span>
              <span class="corpo-secao-informacao">{{
                eventoObj.acs_status
              }}</span>
            </div>
            <div
              v-if="
                ['AP', 'CA'].includes(eventoObj.agenda_calendario_status_id)
              "
              class="col-12 col-sm-6 col-xl-4 modal-detalhes-corpo-secao"
            >
              <span class="corpo-secao-descricao">Sincronização</span>
              <span class="corpo-secao-informacao">{{
                statusSincronizacao(eventoObj.agc_sincronizado)
              }}</span>
            </div>
            <div class="col-12 col-sm-6 col-xl-4 modal-detalhes-corpo-secao">
              <span class="corpo-secao-descricao">Data do evento</span>
              <span class="corpo-secao-informacao">{{
                formatarData(
                  eventoObj.agc_data_inicio,
                  eventoObj.agc_hora_inicio
                )
              }}</span>
            </div>
            <div
              class="col-12 col-sm-6 col-xl-4 modal-detalhes-corpo-secao"
              :class="{
                'mt-5': ['AP', 'CA'].includes(
                  eventoObj.agenda_calendario_status_id
                )
              }"
            >
              <span class="corpo-secao-descricao">Data de criação</span>
              <span class="corpo-secao-informacao">{{
                formatarDataString(
                  eventoObj.agc_data_cadastro,
                  "DD/MM/YYYY HH:mm"
                )
              }}</span>
            </div>
            <template v-for="(item, key) in eventoObj.agc_json_data">
              <div
                v-if="key.endsWith('_txt') || key == 'destinatario'"
                :key="key"
                class="col-12 col-sm-6 col-xl-4 modal-detalhes-corpo-secao mt-5"
              >
                <span class="corpo-secao-descricao">{{
                  key[0].toUpperCase() +
                  key
                    .substring(1)
                    .replaceAll("_txt", "")
                    .replaceAll("_id", "")
                    .replaceAll("_", " ")
                }}</span>
                <span class="corpo-secao-informacao">{{
                  key == "destinatario" ? phoneMask(item) : titleCase(item)
                }}</span>
              </div>
            </template>
            <div
              class="col-12 col-sm-6 col-xl-4 modal-detalhes-corpo-secao mt-5"
            >
              <span class="corpo-secao-descricao">Regra</span>
              <span class="corpo-secao-informacao">
                {{ eventoObj.mar_nome }}
              </span>
            </div>
          </div>
          <div class="row mt-5">
            <div v-if="eventoObj.agc_descricao" class="col-12">
              <span class="corpo-secao-descricao">Observação</span>
              <span class="corpo-secao-informacao">{{
                eventoObj.agc_descricao
              }}</span>
            </div>
          </div>
        </b-collapse>
      </div>

      <div
        v-if="Object.keys(eventoObj.arquivos).length > 0"
        class="align-items-center mb-10 mt-5 detalhes-collapse overflow-hidden"
      >
        <div
          class="row pb-5 align-items-center overflow-hidden"
          style="padding: 16px"
        >
          <div class="col-12 col-sm-8 col-xl-8 align-items-center">
            <span class="corpo-secao-informacao">
              <strong>Arquivos</strong></span
            >
          </div>

          <div class="col-12 col-sm-4 col-xl-4 d-flex flex-row-reverse">
            <!-- style="display: flex; justify-content: end" -->
            <b-button
              v-b-toggle="'cardCollapseArquivo'"
              style="padding: 0; border: none"
            >
              <a
                class="btn btn-icon btn-sm btn-light-primary card-button-collapse d-flex justify-content-center"
                style="width: 40px"
                @click="
                  isDetalhesCollapsedArquivo = !isDetalhesCollapsedArquivo
                "
              >
                <i
                  v-if="isDetalhesCollapsedArquivo"
                  class="ki ki-arrow-down icon-nm"
                  style="color: inherit"
                ></i>
                <i
                  v-if="!isDetalhesCollapsedArquivo"
                  class="ki ki-arrow-up icon-nm"
                  style="color: inherit"
                ></i>
              </a>
            </b-button>
          </div>
        </div>
        <b-collapse
          id="cardCollapseArquivo"
          style="border-top: 1px solid #eee; padding: 16px"
        >
          <div class="row">
            <div
              class="col"
              v-for="(arquivos, tipo) in eventoObj.arquivos"
              :key="tipo"
            >
              <p>
                <b>{{ tipos[tipo] ? tipos[tipo].descricao : tipo }}:</b>
              </p>

              <template v-if="tipo == 'midia'">
                <div class="symbol-list d-flex flex-wrap">
                  <template v-for="(arquivo, chave) in arquivos">
                    <div
                      class="symbol symbol-80 mr-3 border"
                      style="border-color: #b5b5c3 !important; cursor: pointer"
                      :key="chave"
                      @click.prevent="abrirPreview(arquivo)"
                    >
                      <template v-if="arquivo.tipo_original == 'image'">
                        <img :src="arquivo.caminho_s3" @contextmenu.prevent />
                      </template>

                      <template v-else-if="arquivo.tipo_original == 'video'">
                        <video
                          :src="`${arquivo.caminho_s3}#t=1`"
                          width="80"
                          height="80"
                          @contextmenu.prevent
                        ></video>
                      </template>
                    </div>
                  </template>
                </div>
              </template>

              <template v-else-if="tipo == 'document' || tipo == 'file'">
                <template v-for="(arquivo, chave) in arquivos">
                  <div
                    class="row border rounded py-6 px-4 mx-0"
                    style="border-color: #b5b5c3 !important; cursor: pointer"
                    :key="chave"
                    @click.prevent="abrirPreview(arquivo)"
                  >
                    <div class="col">
                      <span class="symbol symbol-50 mr-2">
                        <!-- <b-icon
                          v-if="tipos[arquivo.tipo].icone"
                          :icon="tipos[arquivo.tipo].icone"
                          class="h1 m-0"
                        ></b-icon> -->
                      </span>
                      <span class="text-dark-75 font-weight-bold">{{
                        `${arquivo.descricao}`
                      }}</span>
                    </div>
                  </div>
                </template>
              </template>

              <template v-else>
                <template v-for="(arquivo, chave) in arquivos">
                  {{ arquivo }} {{ chave }}
                  <div class="row mx-0" :key="chave">
                    <div class="col px-0">
                      <audio
                        controls
                        :src="arquivo.caminho_s3"
                        class="mb-2"
                      ></audio>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </b-collapse>
      </div>

      <!-- <p><b>Parâmetros:</b></p> -->
      <json-viewer
        v-if="false"
        :value="evento.agc_json_data ? JSON.parse(evento.agc_json_data) : {}"
        class="bg-primary-o-20 rounded mb-3"
      ></json-viewer>
      <div
        v-if="evento.sincronizacoes && evento.sincronizacoes.length > 0"
        class="align-items-center mb-10 mt-5 detalhes-collapse overflow-hidden"
      >
        <div
          class="row pb-5 align-items-center overflow-hidden d-flex justify-content-between"
          v-b-toggle="'cardCollapseRegister'"
          @click="isRegisterCollapsed = !isRegisterCollapsed"
          style="padding: 16px"
        >
          <div class="col-12 col-sm-6 col-xl-9 align-items-center">
            <span class="modal-detalhes-corpo-secao-informacao">
              <strong>Histórico de sincronização</strong>
            </span>
          </div>
          <div class="col-12 col-sm-4 col-xl-2 d-flex flex-row-reverse">
            <!-- style="display: flex; justify-content: end" -->
            <a>
              <i
                v-if="isRegisterCollapsed"
                class="ki ki-arrow-down icon-nm ml-2"
                style="color: black"
              ></i>
              <i
                v-else
                class="ki ki-arrow-up icon-nm ml-2"
                style="color: black"
              ></i>
            </a>
          </div>
        </div>
        <b-collapse
          id="cardCollapseRegister"
          visible
          style="border-top: 1px solid #eee"
        >
          <div class="col">
            <b-table
              id="tabela-historico"
              :fields="fields"
              :items="evento.sincronizacoes"
              responsive="sm"
              striped
              hover
              :per-page="porPagina"
              :current-page="paginaAtual"
              :key="componentKey"
            >
              <template #cell(acl_data_cadastro)="data">
                <span style="width: 100px">
                  {{ formatarDataString(data.value, "DD/MM/YYYY HH:mm") }}
                </span>
              </template>

              <template #cell(acl_data_atualizacao)="data">
                <span style="width: 100px">
                  {{ formatarDataString(data.value, "DD/MM/YYYY HH:mm") }}
                </span>
              </template>

              <template #cell(status)="data">
                <b-badge
                  :variant="data.value == 'success' ? 'success' : 'danger'"
                  >{{ data.value == "success" ? "SUCESSO" : "ERRO" }}</b-badge
                >
              </template>

              <template #cell(show_details)="data">
                <i
                  v-b-tooltip.hover
                  title="Relatório Detalhado"
                  :class="`menu-icon icon-md flaticon2-infographic warning`"
                  @click="
                    (e) =>
                      checkSuperAdmin &&
                      data.item.status == 'error' &&
                      data.toggleDetails()
                  "
                  :style="`${
                    data.item.status == 'error' && checkSuperAdmin
                      ? 'cursor: pointer; color: #ffa800'
                      : ''
                  }`"
                />
              </template>

              <template #row-details="row">
                <json-viewer
                  :value="{
                    comando: row.item.acl_comando,
                    erro: JSON.parse(row.item.acl_msg_erro)
                  }"
                ></json-viewer>
              </template>
            </b-table>

            <div
              class="d-flex justify-content-center"
              v-if="evento.sincronizacoes.length > porPagina"
            >
              <b-pagination
                class="d-flex align-self-center"
                v-model="paginaAtual"
                :total-rows="evento.sincronizacoes.length"
                :per-page="porPagina"
                aria-controls="tabela-historico"
              ></b-pagination>
            </div>
          </div>
        </b-collapse>
      </div>
    </b-modal>
    <modal-preview :arquivo="previewArquivo" ref="mdlPreview"></modal-preview>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import moment from "moment";
import download from "downloadjs";

import JsonViewer from "vue-json-viewer";
import "vue-json-viewer/style.css";
import ModalPreview from "./ModalPreview";
import API_LINKS from "../../automacao/api.links";
import { mapGetters } from "vuex";

export default {
  props: ["evento", "conta"],

  components: { JsonViewer, "modal-preview": ModalPreview },

  computed: {
    ...mapGetters(["checkSuperAdmin"])
  },

  data() {
    return {
      cabecalho: [
        {
          key: "thumb",
          label: "",
          class: "text-center align-middle col-1"
        },
        {
          key: "nome",
          label: "Nome",
          class: "align-middle"
        },
        {
          key: "acoes",
          label: "Ações",
          class: "text-center align-middle col-2"
        }
      ],
      tipos: {
        image: {
          descricao: "Imagem",
          icone: "file-earmark-image"
        },
        video: {
          descricao: "Vídeo",
          icone: "file-earmark-play"
        },
        audio: {
          descricao: "Áudio",
          icone: "file-earmark-music"
        },
        document: {
          descricao: "Documento",
          icone: "file-earmark-text"
        },
        midia: {
          descricao: "Imagem e Vídeo",
          icone: "file-earmark-media"
        }
      },
      eventoObj: null,
      eventoCopia: null,
      previewArquivo: {},
      audio: null,
      playing: null,
      isDetalhesCollapsed: false,
      isDetalhesCollapsedArquivo: false,
      isRegisterCollapsed: false,
      porPagina: 4,
      paginaAtual: 0,
      componentKey: 0,
      fields: [
        { key: "acl_data_cadastro", label: "Cadastro", class: "text-left" },
        {
          key: "acl_data_atualizacao",
          label: "Atualização",
          class: "text-left"
        },
        { key: "status", label: "Status", class: "text-center" },
        {
          key: "show_details",
          label: "Relatório",
          class: "text-center"
        }
      ]
    };
  },

  methods: {
    abrir(evento) {
      this.eventoObj = { ...evento };
      this.eventoCopia = { ...evento };
      this.eventoObj.agc_json_data = evento.agc_json_data
        ? JSON.parse(evento.agc_json_data)
        : evento.agc_json_data;
      this.$forceUpdate();
      setTimeout(() => {
        this.$refs["mdlDetalheEvento"].show();
      }, 200);
    },

    editar(evento) {
      this.$emit("editarEvento", evento);
    },

    async cancelar(evento) {
      let dataEventoMoment = moment(
        `${this.evento.agc_data_inicio} ${this.evento.agc_hora_inicio}`
      );

      let { data } = await ApiService.get(API_LINKS.listaMensagensAgenda);
      let avisa_cancela_agendamento = data.data.mensagens.cancelado.mar_texto;

      Swal.fire({
        title: "Deseja realmente cancelar este evento?",
        showCancelButton: true,
        confirmButtonText: "Sim, desejo",
        cancelButtonText: "Manter evento",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-danger"
        },
        buttonsStyling: false,
        width: "650px",
        heightAuto: false,
        html: `
        <div class="form-group d-flex flex-column">
          <div class="d-flex w-100 mb-2">Texto de envio</div>
          <textarea readonly rows="8" cols="35" id="swal-textarea" style="
            padding: 6px 10px;
            box-sizing: border-box;
            border: 2px solid #ccc;
            border-radius: 4px;
            background-color: #f8f8f8;
            outline: none;
            font-size: 12px;
            resize: none;"
          >${avisa_cancela_agendamento
            .replaceAll(
              "{{1}}",
              this.eventoObj.agc_json_data.cliente_nome.trim()
            )
            .replaceAll("{{2}}", dataEventoMoment.format("DD/MM/YYYY"))
            .replaceAll("{{3}}", dataEventoMoment.format("HH:mm"))
            .replaceAll(
              "{{4}}",
              this.eventoObj.agc_json_data.prestador_id_txt
                ? this.eventoObj.agc_json_data.prestador_id_txt.trim()
                : "NÃO INFORMADO"
            )
            .replaceAll(
              "{{5}}",
              this.eventoObj.agc_json_data.exame_id_txt
                ? this.eventoObj.agc_json_data.exame_id_txt.trim()
                : "NÃO INFORMADO"
            )
            .replaceAll(
              "{{6}}",
              this.eventoObj.agc_json_data?.horario_ordem_chegada
                ? this.eventoObj.agc_json_data.horario_ordem_chegada.trim()
                : "COMO INFORMADO ANTERIORMENTE"
            )}</textarea>
          <div class="d-flex align-items-center mt-3 mb-3">
            <input type="checkbox" checked placeholder="E-mail" id="swal-checkbox">
            <label class="mb-0 ml-2" for="swal-checkbox">Enviar mensagem para notificar o cliente</label>
          </div>
        </div>
          `,
        preConfirm: () => {
          return [
            document.getElementById("swal-textarea").value,
            document.getElementById("swal-checkbox").checked
          ];
        }
      }).then((result) => {
        if (result.value) {
          ApiService.post(`agenda/calendario/cancelar-evento/${evento}`, {
            mensagem: result.value[1] ? result.value[0] : null
          }).then(() => {
            this.$refs["mdlDetalheEvento"].hide();
            this.$emit("eventoExcluido", evento);

            Swal.fire({
              title: "CANCELADO",
              text: "Evento cancelado com sucesso!",
              icon: "success",
              heightAuto: false
            });

            this.$emit("atualizar");
          });
        }
      });
    },
    phoneMask(value) {
      if (typeof value != "string") return value;
      if (!value) return "";
      value = value.replace("55", "");
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d{2})(\d)/, "($1) $2");
      value = value.replace(/(\d)(\d{4})$/, "$1-$2");
      return value;
    },
    titleCase(str) {
      if (typeof str != "string") {
        return str;
      }
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
    isValidPhoneNumber(phoneNumber) {
      // Remove todos os caracteres que não sejam números e o sinal de "+"
      phoneNumber = phoneNumber.replace(/\D/g, "");

      // Verifica se o número de telefone tem pelo menos 8 dígitos
      if (/^[1-9][0-9]{7,}$/.test(phoneNumber)) {
        // O número de telefone é válido
        return true;
      }

      // Verifica se o número de telefone começa com um sinal de "+" ou "00"
      if (/^(?:\+|00)/.test(phoneNumber)) {
        // Remove o sinal de "+" ou "00" e tenta validar o número sem o código de país
        phoneNumber = phoneNumber.replace(/^(?:\+|00)/, "");
        return /^[1-9][0-9]{7,}$/.test(phoneNumber);
      }

      // Verifica se o número de telefone tem um código de país válido
      if (/^(?:\+|00)?(\d{1,3})?\s*([1-9][0-9]{7,})$/.test(phoneNumber)) {
        // O número de telefone é válido
        return true;
      }

      // O número de telefone é inválido
      return false;
    },

    formatarData(data, hora) {
      return moment(`${data} ${hora}`).format("DD/MM/YYYY HH:mm");
    },

    formatarDataString(dataHora, format) {
      return moment(dataHora).format(format);
    },

    async downloadArquivo(caminho, nome, extensao) {
      ApiService.post(
        "agenda/calendario/download-arquivo",
        { caminho: caminho, nome: `${nome}.${extensao}` },
        {
          responseType: "blob"
        }
      ).then((response) => {
        const content = response.headers["content-type"];
        download(response.data, `teste.jpeg`, content);
      });
    },

    enviarMensagemStatusEvento(telefone, mensagem) {
      ApiService.post(`relatorio/detalhado/envio-mensagem/${telefone}`, {
        mensagem
      }).then((response) => {
        console.log(response, "response");
        const mensagemEnviada =
          response.data.mensagem &&
          response.data.mensagem == "Mensagem enviada com sucesso.";
        this.$emit("toast", {
          severity: !mensagemEnviada ? "error" : "success",
          summary: !mensagemEnviada
            ? "Não foi possível notificar cliente"
            : "Cliente notificado com sucesso",
          detail: !mensagemEnviada
            ? `A mensagem não foi enviada para notificar o cliente da ação realizada. Código: ${response.data}`
            : `O cliente foi notificado com sucesso`,
          life: 10000
        });
      });
    },

    async aprovarEvento(evento) {
      let dataEventoMoment = moment(
        `${this.evento.agc_data_inicio} ${this.evento.agc_hora_inicio}`
      );

      let { data } = await ApiService.get(API_LINKS.listaMensagensAgenda);
      const txt = {
        HM: data.data.mensagens.aprovadoHorarioMarcado.mar_texto,
        OC: data.data.mensagens.aprovadoOrdemChegada.mar_texto
      };
      let avisa_agendamento_detalhado =
        txt[this.evento.mar_tipo_atendimento] ?? "";
      Swal.fire({
        title: "Deseja aprovar este evento?",
        showCancelButton: true,
        confirmButtonText: "Aprovar",
        cancelButtonText: "Cancelar",
        width: "650px",
        heightAuto: false,
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-primary"
        },
        html: `
        <div class="form-group d-flex flex-column">
          <div class="d-flex w-100 mb-2">Texto de envio</div>
          <textarea readonly rows="8" cols="35" id="swal-textarea" style="
            padding: 6px 10px;
            box-sizing: border-box;
            border: 2px solid #ccc;
            border-radius: 4px;
            background-color: #f8f8f8;
            outline: none;
            font-size: 12px;
            resize: none;"
            
          >${avisa_agendamento_detalhado
            .replaceAll(
              "{{1}}",
              this.eventoObj.agc_json_data.cliente_nome.trim()
            )
            .replaceAll("{{2}}", dataEventoMoment.format("DD/MM/YYYY"))
            .replaceAll("{{3}}", dataEventoMoment.format("HH:mm"))
            .replaceAll(
              "{{4}}",
              this.eventoObj.agc_json_data.prestador_id_txt
                ? this.eventoObj.agc_json_data.prestador_id_txt.trim()
                : "NÃO INFORMADO"
            )
            .replaceAll(
              "{{5}}",
              this.eventoObj.agc_json_data.exame_id_txt
                ? this.eventoObj.agc_json_data.exame_id_txt.trim()
                : "NÃO INFORMADO"
            )
            .replaceAll(
              "{{6}}",
              this.eventoObj.agc_json_data?.horario_ordem_chegada
                ? this.eventoObj.agc_json_data.horario_ordem_chegada.trim()
                : "COMO INFORMADO ANTERIORMENTE"
            )}</textarea>
          <div class="d-flex align-items-center mt-3 mb-3">
            <input type="checkbox" checked placeholder="E-mail" id="swal-checkbox">
            <label class="mb-0 ml-2" for="swal-checkbox">Enviar mensagem para notificar o cliente</label>
          </div>
        </div>
          `,
        buttonsStyling: false,
        preConfirm: () => {
          return [
            document.getElementById("swal-textarea").value,
            document.getElementById("swal-checkbox").checked
          ];
        }
      }).then((result) => {
        if (result.value) {
          ApiService.post(
            `agenda/calendario/aprovar-evento/${evento}`,
            {
              mensagem: result.value[1] ? result.value[0] : null
            },
            null,
            false
          ).then(() => {
            this.$refs["mdlDetalheEvento"].hide();

            Swal.fire({
              title: "APROVADO",
              text: "Evento aprovado com sucesso!",
              icon: "success",
              heightAuto: false
            });
            this.$emit("atualizar");
          });
        }
      });
    },

    async recusarEvento(evento) {
      let dataEventoMoment = moment(
        `${this.evento.agc_data_inicio} ${this.evento.agc_hora_inicio}`
      );

      let { data } = await ApiService.get(API_LINKS.listaMensagensAgenda);
      let avisa_recusa_agendamento = data.data.mensagens.recusado.mar_texto;

      Swal.fire({
        title: "Deseja recusar este evento?",
        showCancelButton: true,
        confirmButtonText: "Recusar",
        cancelButtonText: "Cancelar",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-danger"
        },
        buttonsStyling: false,
        width: "650px",
        heightAuto: false,
        html: `
        <div class="form-group d-flex flex-column">
          <div class="d-flex w-100 mb-2">Texto de envio</div>
          <textarea readonly rows="8" cols="35" id="swal-textarea" style="
            padding: 6px 10px;
            box-sizing: border-box;
            border: 2px solid #ccc;
            border-radius: 4px;
            background-color: #f8f8f8;
            outline: none;
            font-size: 12px;
            resize: none;"
          >${avisa_recusa_agendamento
            .replaceAll(
              "{{1}}",
              this.eventoObj.agc_json_data.cliente_nome.trim()
            )
            .replaceAll("{{2}}", dataEventoMoment.format("DD/MM/YYYY"))
            .replaceAll("{{3}}", dataEventoMoment.format("HH:mm"))
            .replaceAll(
              "{{4}}",
              this.eventoObj.agc_json_data.prestador_id_txt
                ? this.eventoObj.agc_json_data.prestador_id_txt.trim()
                : "NÃO INFORMADO"
            )
            .replaceAll(
              "{{5}}",
              this.eventoObj.agc_json_data.exame_id_txt
                ? this.eventoObj.agc_json_data.exame_id_txt.trim()
                : "NÃO INFORMADO"
            )
            .replaceAll(
              "{{6}}",
              this.eventoObj.agc_json_data?.horario_ordem_chegada
                ? this.eventoObj.agc_json_data.horario_ordem_chegada.trim()
                : "COMO INFORMADO ANTERIORMENTE"
            )}</textarea>
          <div class="d-flex align-items-center mt-3 mb-3">
            <input type="checkbox" checked placeholder="E-mail" id="swal-checkbox">
            <label class="mb-0 ml-2" for="swal-checkbox">Enviar mensagem para notificar o cliente</label>
          </div>
        </div>
          `,
        preConfirm: () => {
          return [
            document.getElementById("swal-textarea").value,
            document.getElementById("swal-checkbox").checked
          ];
        }
      }).then((result) => {
        if (result.value) {
          ApiService.post(`agenda/calendario/recusar-evento/${evento}`, {
            mensagem: result.value[1] ? result.value[0] : null
          }).then(() => {
            this.$refs["mdlDetalheEvento"].hide();

            Swal.fire({
              title: "RECUSADO",
              text: "Evento recusado com sucesso!",
              icon: "success",
              heightAuto: false
            });
            this.$emit("atualizar");
          });
        }
      });
    },

    abrirPreview(arquivo) {
      this.previewArquivo = arquivo;

      this.$refs["mdlPreview"].abrir();
    },

    playAudio(link) {
      if (this.audio) {
        this.audio.pause();
        this.audio = null;
        this.playing = null;
      } else {
        this.audio = new Audio(link);

        this.audio.addEventListener("canplaythrough", () => {
          this.audio.play();
          this.playing = link;
        });

        this.audio.addEventListener("ended", () => {
          this.audio = null;
          this.playing = null;
        });
      }
    },
    pegarIcone(status, sincronizado) {
      switch (status) {
        case "AP":
          if (sincronizado == "S") {
            return "check";
          }
          if (sincronizado == "P") {
            return "check-sync";
          }
          if (sincronizado == "E") {
            return "check-erro";
          }
          break;
        case "CA":
          if (sincronizado == "S") {
            return "x";
          }
          if (sincronizado == "P") {
            return "x-sync";
          }
          if (sincronizado == "E") {
            return "x-erro";
          }
          break;
        case "RE":
          return "refused";
        case "NO":
          return "new";

        default:
          break;
      }
    },
    statusSincronizacao(status) {
      let opcoes = {
        S: "Sincronizado",
        P: "Pendente",
        E: "Erro"
      };
      return opcoes[status] ? opcoes[status] : "";
    },
    hideModal() {
      setTimeout(() => {
        this.eventoObj = null;
      }, 500);
    }
  }
};
</script>

<style>
.swal-wide {
  width: 850px !important;
}

.modal-detalhes-corpo-secao-informacao {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 1.33rem;
}

.corpo-secao-descricao {
  display: block;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 0.9rem;
}

.corpo-secao-informacao {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 1.33rem;
}

.detalhes-collapse {
  outline: 1px solid #eee;
  border-left: red !important;
  padding: 0;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 0.42rem;
}

.back-arrow-squared {
  border: 1.2px solid #b8b8b8;
  border-radius: 4px;
  transform: rotate(-90deg);
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.largura-personalizada .swal2-container {
  width: 600px !important;
}

.swal2-popup .swal2-modal .swal2-show {
  width: 600px;
}
.icon {
  display: inline;
  width: 100%;
}

.icon-size-p {
  width: 10px;
  height: 10px;
}

.icon-size-m {
  width: 18px;
  height: 18px;
}

.icon-size-g {
  width: 24px;
  height: 24px;
}

.check {
  content: url(../../../../../assets/icons/check.svg);
}

.check-sync {
  content: url(../../../../../assets/icons/check-sync.svg);
}

.check-erro {
  content: url(../../../../../assets/icons/check-erro.svg);
}

.x {
  content: url(../../../../../assets/icons/x.svg);
}

.x-sync {
  content: url(../../../../../assets/icons/x-sync.svg);
}

.x-erro {
  content: url(../../../../../assets/icons/x-erro.svg);
}

.new {
  content: url(../../../../../assets/icons/new.svg);
}

.refused {
  content: url(../../../../../assets/icons/refused.svg);
}

.paperclip {
  content: url(../../../../../assets/icons/paperclip.svg);
}
</style>
